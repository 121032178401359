/* You can add global styles to this file, and also import other style files */

/* Importing Bootstrap SCSS file. */
@import "~bootstrap/scss/bootstrap";

:root {
	--dark-bgcolor: #282030;
	--light-bgcolor: #473658;
	--light-text-color: #dddddd;
	--dark-text-color: #292929;
	--light-pink: #ff62ea;
	--dark-pink: #cf4dbe;
}

body {
	background-color: var(--dark-bgcolor) !important;
}

.toast-container .ngx-toastr {
	box-shadow: none !important;
	width: 400px !important;
	margin-bottom: 12px !important;
	margin: auto;
}

.toast-container {
	width: none !important;
	transform: translateX(-50%) !important;
	bottom: 0 !important;
	left: 50% !important;
}

// html, body { height: 100%; }
body {
	margin: 0;
	font-family: Roboto, "Helvetica Neue", sans-serif;
}
